import React, { useState, useEffect } from "react";

//react-router dom
import { Link, useNavigate } from "react-router-dom";
// components
import Layout from "../../layout/Layout";
import Excoimage from "../../../src/image/favicon.ico";
import ShortResponse from "../Ratings/short response";
import SurveyFinishPage from "../surveyFinish";
// mui material
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormHelperText } from "@mui/material";
import { Translate } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
//react-redux
import { useSelector, useDispatch } from "react-redux";
import {
  addingQuestionTemplate,
  assessmentTrackingTemplate,
  assessmentTrackingTemplateForClients,
} from "../../features/dashboard/dashBoardSlice";
import { isLoggedIn } from "../../features/auth/authSlice";
import { registerables } from "chart.js";
import { theme } from "../../theme/Theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem",
    },
  },
  sliderParent: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
    marginLeft: "2rem !important",
    width: "90% !important",

    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column !important",
      alignItems: "center !important",
      marginLeft: "2rem !important",
      width: "`100% !important",
    },
  },

  radiocontrol: {
    marginLeft: "5rem !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0rem !important",
      alignSelf: "start",
    },
  },

  buttonParent: {
    paddingBottom: "1rem",
    marginLeft: "1rem",
  },

  buttonParentOne: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center !important",
    width: "100% !important",

    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      justifyContent: "space-between !important",
      alignItems: "center !important",
    },
  },

  buttonParentTwo: {
    width: "55% !important",
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "flex-end !important",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end !important",
    },
  },

  progressParent: {
    width: "45% !important",

    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "flex-end !important",
  },

  progressParentTwo: {
    color: "#009900 !important",
    width: "40% !important",

    [theme.breakpoints.down("sm")]: {
      color: "#009900 !important",
      width: "100% !important",
      marginLeft: "0.5rem !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      color: "#009900 !important",
      width: "50% !important",
      marginLeft: "0.5rem !important",
    },
    // [theme.breakpoints.down("md")]: {
    //   width: "50% ",

    // },
  },

  prebutton: {
    marginLeft: "1rem !important",
    width: "8rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "5rem !important",
      fontSize: "0.5rem !important",
      marginLeft: "0rem !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "8rem !important",

      marginLeft: "1rem !important",
    },
  },
  nextbut: {
    marginLeft: "1rem !important",
    width: "8rem !important",
    [theme.breakpoints.down("sm")]: {
      width: "5rem !important",
      fontSize: "0.5rem !important",
      marginLeft: "1rem !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "8rem !important",
      marginLeft: "1rem !important",
    },
  },
  reviewbut: {
    marginLeft: "1rem !important",
    width: "8rem !important",

    [theme.breakpoints.down("sm")]: {
      width: "5rem !important",
      fontSize: "0.5rem !important",
      marginLeft: "1rem !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "8rem !important",
      marginLeft: "1rem !important",
    },
  },

  mark: {
    width: "10rem",
    height: "5rem",
    backgroundColor: "#000",
    marginTop: "-3px",
  },
  "& .MuiSlider-mark": {
    height: "5rem",
    width: "5rem",
  },
  label: {
    color: "red",
  },
}));

const Assesment = () => {
  const [countValue, setCountValue] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [dataSet, setDataSet] = React.useState([]);
  const [pageNo, setPageNo] = React.useState(0);
  const [pageArr, setPageArr] = React.useState([]);
  const [sliderValue, setSliderValue] = React.useState(20);
  const [slValue, setSlValue] = React.useState("");
  const [ratingData, setRatingData] = React.useState([]);
  const [radioValue, setRadioValue] = React.useState(true);
  const [progressValue, setProgressValue] = React.useState(0);
  const [lastPageToggle, setLastPageToggle] = React.useState(false);
  const [navigateToggle, setNavigateToggle] = React.useState(false);
  const { assessmentTemplateData } = useSelector((props) => ({
    ...props.dashBoard,
  }));
  const { loggedIn } = useSelector((props) => ({
    ...props.auth,
  }));

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const marks = [
    {
      value: 0,
      label: " Never",
    },
    {
      value: 25,
      label: "Rarely",
    },
    {
      value: 50,
      label: "Sometimes",
    },
    {
      value: 75,
      label: "Often",
    },
    {
      value: 100,
      label: "Always",
    },
  ];

  let dispatch = useDispatch();
  let navigate = useNavigate();
  // Arrage Data for Pagenation

  let currentSection = "";
  let currentDimension = "";
  let currentQuestion = "";
  let currentQuestionType = "";
  let questionType = "";
  let obj = {};
  let data = [];
  let currentPageNo = 1;

  let isCheckingSelf = localStorage.getItem("role");

  assessmentTemplateData.length > 0 &&
    assessmentTemplateData.forEach((item) => {
      if (
        currentSection !== item.as_sectionName ||
        currentDimension !== item.ad_dimensionName ||
        ((currentQuestionType === "SR" || currentQuestionType === "SLR") &&
          (item.aq_questionType === "Rating" ||
            item.aq_questionType === "OC")) ||
        item.aq_questionType === "SR" ||
        item.aq_questionType === "SLR"
      ) {
        obj.pageNumber = currentPageNo;
        obj.data = [];
        let newobj;

        if (item?.rt_ratingResponse === 0) {
          newobj = JSON.parse(JSON.stringify(item));

          newobj.rt_ratingResponse = 9;
        }
        if (newobj) {
          obj.data.push(newobj);
        } else {
          obj.data.push(item);
        }
        data.push(obj);
        obj = {};
        currentPageNo++;
        currentSection = item.as_sectionName;
        currentDimension = item.ad_dimensionName;
        currentQuestionType = item.aq_questionType;
      } else {
        currentSection = item.as_sectionName;
        currentDimension = item.ad_dimensionName;
        currentQuestion = item.aq_questionName;
        const lastIndex = data.length - 1;

        let newobj;

        if (item?.rt_ratingResponse === 0) {
          newobj = JSON.parse(JSON.stringify(item));

          newobj.rt_ratingResponse = 9;
        }
        if (newobj) {
          data[lastIndex].data.push(newobj);
        } else {
          data[lastIndex].data.push(item);
        }
      }
    });

  const handlePageCountClick = () => {
    let result = pageNo + 1;

    localStorage.setItem("pageLocalNumber", result);
    localStorage.getItem("pageLocalNumber");
    setPageNo(result);
    setSlValue("");
    if (isCheckingSelf === "Self") {
      dispatch(
        assessmentTrackingTemplateForClients({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    } else {
      dispatch(
        assessmentTrackingTemplate({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    }

    setNavigateToggle(false);
  };

  const handlePageCountPreviousClick = () => {
    let result = pageNo - 1;
    localStorage.setItem("pageLocalNumber", result);
    localStorage.getItem("pageLocalNumber");
    setPageNo(result);
    setSlValue("");
    if (isCheckingSelf === "Self") {
      dispatch(
        assessmentTrackingTemplateForClients({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    } else if (isCheckingSelf === "stakeholder") {
      dispatch(
        assessmentTrackingTemplate({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    }
    setNavigateToggle(false);
  };

  const handleSliderChange = (e, items, itemId) => {
    console.log(e.target.value);
    let result =
      ratingData?.length > 0
        ? ratingData?.map((data) => {
            if (data?.aq_id === itemId) {
              return {
                ...data,
                rt_ratingResponse:
                  (e.target.value === 0 && 0) ||
                  (e.target.value === 1 && 0) ||
                  (e.target.value === 25 && 25) ||
                  (e.target.value === 50 && 50) ||
                  (e.target.value === 75 && 75) ||
                  (e.target.value === 100 && 100) ||
                  e.target.value,
              };
            }
            return data;
          })
        : data.length > 0 &&
          data[pageNo]?.data?.length > 0 &&
          data[pageNo]?.data?.map((data) => {
            if (data?.aq_id === itemId) {
              return {
                ...data,
                rt_ratingResponse:
                  (e.target.value === 0 && 0) ||
                  (e.target.value === 1 && 0) ||
                  (e.target.value === 25 && 25) ||
                  (e.target.value === 50 && 50) ||
                  (e.target.value === 75 && 75) ||
                  (e.target.value === 100 && 100) ||
                  e.target.value,
              };
            }
            return data;
          });

    setRatingData(result);
    setNavigateToggle(true);
    dispatch(
      addingQuestionTemplate({
        assessmentTrackingId: localStorage.getItem("state"),
        assessmentQuestionId: itemId,
        ratingResponse:
          (e.target.value === 0 && 1) ||
          (e.target.value === 25 && 2) ||
          (e.target.value === 50 && 3) ||
          (e.target.value === 75 && 4) ||
          (e.target.value === 100 && 5),
      })
    ).then((res) => {
      if (res?.payload?.data?.code !== 201) {
        navigate("/exco/dashboard");
      }
    });
  };

  const handleSlChange = (e, items) => {
    let body =
      items.aq_questionType === "SR"
        ? {
            assessmentTrackingId: localStorage.getItem("state"),
            assessmentQuestionId: items?.aq_id,
            shortResponse: e.target.value,
          }
        : {
            assessmentTrackingId: localStorage.getItem("state"),
            assessmentQuestionId: items?.aq_id,
            longResponse: e.target.value,
          };

    setSlValue(e.target.value);
    dispatch(addingQuestionTemplate(body));
  };

  const handleRadioChange = (e, itemId) => {
    let result =
      ratingData?.length > 0
        ? ratingData?.map((data) => {
            if (data?.aq_id === itemId) {
              return {
                ...data,
                rt_ratingResponse: 9,
              };
            }
            return data;
          })
        : data.length > 0 &&
          data[pageNo]?.data?.length > 0 &&
          data[pageNo]?.data?.map((data) => {
            if (data?.aq_id === itemId) {
              return {
                ...data,
                rt_ratingResponse: 9,
              };
            }
            return data;
          });

    setRatingData(result);
    setNavigateToggle(true);
    dispatch(
      addingQuestionTemplate({
        assessmentTrackingId: localStorage.getItem("state"),
        assessmentQuestionId: itemId,
        ratingResponse: 0,
      })
    ).then((res) => {
      if (res?.payload?.data?.code !== 201) {
        navigate("/exco/dashboard");
      }
    });
  };

  const handleOptionalChange = (e, itemId) => {
    let result =
      ratingData?.length > 0
        ? ratingData?.map((data) => {
            if (data?.aq_id === itemId) {
              return {
                ...data,
                rt_optionalComment: e.target.value,
              };
            }
            return data;
          })
        : data.length > 0 &&
          data[pageNo]?.data?.length > 0 &&
          data[pageNo]?.data?.map((data) => {
            if (data?.aq_id === itemId) {
              return {
                ...data,
                rt_optionalComment: e.target.value,
              };
            }
            return data;
          });
    setRatingData(result);
    setNavigateToggle(true);

    dispatch(
      addingQuestionTemplate({
        assessmentTrackingId: localStorage.getItem("state"),
        assessmentQuestionId: itemId,
        optionalComment: e.target.value,
      })
    ).then((res) => {
      if (res?.payload?.data?.code !== 201) {
        navigate("/exco/dashboard");
      }
    });
  };

  useEffect(() => {
    if (isCheckingSelf === "Self") {
      dispatch(
        assessmentTrackingTemplateForClients({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    } else if (isCheckingSelf === "stakeholder") {
      dispatch(
        assessmentTrackingTemplate({
          assessmentTrackingId: localStorage.getItem("state"),
        })
      );
    }
    // dispatch(isLoggedIn());
  }, []);

  // useEffect(() => {
  //   return loggedIn === false && navigate("/");
  // }, [loggedIn]);

  useEffect(() => {
    let result1 = data.length > 0 && data.length;
    let result2 = (pageNo / result1) * 100;

    localStorage.setItem("progressValueLocal", result2 ? result2 : 0);
    localStorage.getItem("progressValueLocal");
    setProgress(localStorage.getItem("progressValueLocal"));
  }, [pageNo && assessmentTemplateData]);

  useEffect(() => {
    // setRatingData([]);
    if (
      data[pageNo]?.data[0]?.aq_questionType === "Rating" ||
      data[pageNo]?.data[0]?.aq_questionType === "OC"
    ) {
      setRatingData(data[pageNo].data);
    }

    if (data[pageNo]?.data[0].aq_questionType === "SR") {
      setSlValue(data[pageNo]?.data[0]?.rt_shortResponse);
    } else if (data[pageNo]?.data[0].aq_questionType === "SLR") {
      setSlValue(data[pageNo]?.data[0]?.rt_longResponse);
    }
  }, [pageNo]);

  useEffect(() => {
    let result =
      localStorage.getItem("pageLocalNumber") === null
        ? 0
        : Number(localStorage.getItem("pageLocalNumber"));

    const isCheckingLocalValue = data.find((val, index) => index === result);
    if (!isCheckingLocalValue) {
      result = 0;
    }

    setPageNo(result);

    if (data[result]?.data[0].aq_questionType === "SR") {
      setSlValue(data[result]?.data[0]?.rt_shortResponse);
    } else if (data[result]?.data[0].aq_questionType === "SLR") {
      setSlValue(data[result]?.data[0]?.rt_longResponse);
    }
  }, [assessmentTemplateData]);

  const classes = useStyles();

  const client = `${localStorage.getItem("clientName")}`;
  let firstName = client?.split("\n")[0];

  const isCheckingRoles = () => {
    const client = `${localStorage.getItem("clientName")}`;
    return localStorage.getItem("role") === "Self" ? (
      <>Please rate yourself against the following statements:</>
    ) : (
      <>
        Please rate <span style={{ fontWeight: 200 }}>{client}</span>against the
        following statements:
        {/* <span style={{display:"block",marginTop:"1rem"}}>{firstName} establishes what success looks like</span> */}
      </>
    );
  };

  const navigatePreviewClick = () => {
    navigate("/assessment/preview");
  };

  const handlePageFinish = () => {
    setProgress(100);
    setTimeout(() => {
      setLastPageToggle(true);
    }, 1000);
  };

  // {data.length === 0 && <Stack>You are not assigned questions yet</Stack>}

  return (
    <Layout isSidebarNotShow={true}>
      <Grid
        className={classes.root}
        // sx={{ paddingLeft: "4rem", paddingRight: "4rem" }}
        container
        spacing={2}
      >
        {!lastPageToggle &&
          data.length > 0 &&
          data[pageNo]?.data?.length > 0 &&
          (data[pageNo]?.data[0]?.aq_questionType === "Rating" ||
            data[pageNo]?.data[0]?.aq_questionType === "OC") && (
            <>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "700",
                    fontFamily: "dinBold",
                    alignSelf: "flex-start",
                  }}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {data[pageNo]?.data[0]?.as_sectionName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: 500,
                    fontFamily: "dinBold",
                    alignSelf: "flex-start",
                  }}
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  {data[pageNo]?.data[0]?.ad_dimensionName}
                </Typography>
              </Grid>
              {data[pageNo]?.data[0]?.aq_questionType === "Rating" && (
                <Grid sx={{ marginLeft: "1rem" }} item xs={12} md={12}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",

                      alignSelf: "flex-start",
                    }}
                    variant="h6"
                    gutterBottom
                    component="div"
                  >
                    {isCheckingRoles()}
                  </Typography>
                </Grid>
              )}
              {navigateToggle && ratingData && ratingData.length > 0
                ? ratingData.map((items, index) => {
                    if (ratingData[index]?.aq_questionType === "Rating") {
                      return (
                        <Grid
                          sx={{ marginLeft: "1rem", marginTop: "1rem" }}
                          item
                          sm={12}
                          xs={12}
                          md={12}
                        >
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 400,

                              // paddingLeft: "1rem",
                            }}
                            component="div"
                          >
                            {isCheckingSelf === "Self" ? `I` : `${firstName}`}{" "}
                            {items?.aq_questionName}
                          </Typography>

                          <Stack className={classes.sliderParent}>
                            <Slider
                              track={false}
                              defaultValue={null}
                              sx={{
                                marginTop: "0.3rem",
                                " & .MuiSlider-mark": {
                                  width: "0.25rem",
                                  height: "0.25rem",
                                  borderRadius: "3rem",
                                  background: "white",
                                  borderStyle: "solid",
                                  borderColor: "#002366",
                                },
                                " & .MuiSlider-thumb": {
                                  display: "none",
                                  pointerEvents: "none !important",
                                  position: "absolute !important",
                                  width: "3rem !important",
                                  height: "3rem !important",
                                  marginLeft: "0.3rem !important",
                                  boxSizing: "border-box !important",
                                  backgroundColor: "#ffffff00 !important",
                                  display: "flex",
                                  webkitBoxAlign: "center !important",
                                  alignItems: "center",
                                  WebkitBoxPack: "center !important",
                                  justifyContent: "center",
                                  top: "50%",
                                  transform: "translate(-50%,-50%)",
                                  left: "0%",
                                  backgroundImage:
                                    ((items?.rt_ratingResponse &&
                                      items?.rt_ratingResponse !== 9) ||
                                      items?.rt_ratingResponse === 0) &&
                                    `url(${Excoimage}) !important`,
                                  backgroundRepeat: "no-repeat !important",
                                  backgroundSize: "45px 45px !important",
                                  border: "none transparent !important",
                                  transition: "none 0s ease 0s",
                                },
                                "& .MuiSlider-markLabel": {
                                  color:
                                    (items?.rt_ratingResponse === 9 ||
                                      items?.rt_ratingResponse === null) &&
                                    `rgba(0,0,0,0.6)`,
                                  //  fontWeight:200
                                },
                              }}
                              value={
                                (items?.rt_ratingResponse === 1 && 0) ||
                                (items?.rt_ratingResponse === 0 && 0) ||
                                (items?.rt_ratingResponse === 25 && 25) ||
                                (items?.rt_ratingResponse === 2 && 25) ||
                                (items?.rt_ratingResponse === 50 && 50) ||
                                (items?.rt_ratingResponse === 3 && 50) ||
                                (items?.rt_ratingResponse === 75 && 75) ||
                                (items?.rt_ratingResponse === 4 && 75) ||
                                (items?.rt_ratingResponse === 100 && 100) ||
                                (items?.rt_ratingResponse === 5 && 100)
                              }
                              onChange={(e) =>
                                handleSliderChange(
                                  e,
                                  data[pageNo],
                                  items?.aq_id
                                )
                              }
                              step={25}
                              marks={marks}
                              classes={classes.mark}
                            />
                            <FormControl className={classes.radiocontrol}>
                              <RadioGroup
                                column
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                                onClick={(e) =>
                                  handleRadioChange(e, items?.aq_id)
                                }
                              >
                                <FormControlLabel
                                  value="initialVal"
                                  control={
                                    <Radio
                                      checked={items?.rt_ratingResponse === 9}
                                      size="small"
                                    />
                                  }
                                  labelPlacement="bottom"
                                  label={
                                    <FormLabel
                                      style={{
                                        fontSize: "0.8rem",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Not in a position to observe
                                    </FormLabel>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </Stack>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid sx={{ marginLeft: "1rem" }} item xs={12} md={12}>
                          <Stack
                            sx={{
                              fontSize: "1rem",
                              textAlign: "start",
                              fontWeight: 400,
                            }}
                          >
                            Optional Comments{" "}
                          </Stack>
                          <Stack
                            sx={{
                              width: "98%",
                              fontSize: "0.8rem",
                              textAlign: "end",
                            }}
                          >
                            {" "}
                            {255 -
                              (items?.rt_optionalComment === null
                                ? 0
                                : items?.rt_optionalComment.length)}{" "}
                            Characters remaining
                          </Stack>
                          <TextField
                            sx={{ width: "98%" }}
                            hiddenLabel
                            variant="filled"
                            value={
                              items?.rt_optionalComment
                                ? items?.rt_optionalComment
                                : ""
                            }
                            onChange={(e) =>
                              handleOptionalChange(e, items?.aq_id)
                            }
                          />
                          {items?.rt_optionalComment &&
                            items?.rt_optionalComment.length > 255 && (
                              <FormHelperText
                                error={true}
                                sx={{ color: "red" }}
                              >
                                Response should have less than or equal to 255
                                characters
                              </FormHelperText>
                            )}
                        </Grid>
                      );
                    }
                  })
                : !lastPageToggle &&
                  data.length > 0 &&
                  data[pageNo]?.data?.length > 0 &&
                  data[pageNo]?.data?.map((items, index) => {
                    console.log("data comming");
                    if (
                      data[pageNo]?.data[index]?.aq_questionType === "Rating"
                    ) {
                      return (
                        <Grid
                          sx={{ marginLeft: "1rem", marginTop: "1rem" }}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                            component="div"
                          >
                            {isCheckingSelf === "Self" ? `I` : `${firstName}`}{" "}
                            {items?.aq_questionName}
                          </Typography>

                          <Stack className={classes.sliderParent}>
                            <Slider
                              track={false}
                              defaultValue={null}
                              sx={{
                                marginTop: "0.3rem",
                                " & .MuiSlider-mark": {
                                  width: "0.25rem",
                                  height: "0.25rem",
                                  borderRadius: "3rem",
                                  background: "white",
                                  borderStyle: "solid",
                                  borderColor: "#002366",
                                },
                                " & .MuiSlider-thumb": {
                                  display: "none !important",
                                  pointerEvents: "none !important",
                                  position: "absolute !important",
                                  width: "3rem !important",
                                  height: "3rem !important",
                                  marginLeft: "0.3rem !important",
                                  boxSizing: "border-box !important",
                                  backgroundColor: "#ffffff00 !important",
                                  display: "flex",
                                  webkitBoxAlign: "center !important",
                                  alignItems: "center",
                                  WebkitBoxPack: "center !important",
                                  justifyContent: "center",
                                  top: "50%",
                                  transform: "translate(-50%,-50%)",
                                  left: "0%",
                                  backgroundImage:
                                    ((items?.rt_ratingResponse &&
                                      items?.rt_ratingResponse !== 9) ||
                                      items?.rt_ratingResponse === 0) &&
                                    `url(${Excoimage}) !important`,

                                  backgroundRepeat: "no-repeat !important",
                                  backgroundSize: "45px 45px !important",
                                  border: "none",
                                  transition: "none 0s ease 0s",
                                },
                                "& .MuiSlider-markLabel": {
                                  color:
                                    (items?.rt_ratingResponse === 9 ||
                                      items?.rt_ratingResponse === null) &&
                                    `rgba(0,0,0,0.6)`,
                                  // fontWeight:200
                                },
                              }}
                              value={
                                (items?.rt_ratingResponse === 1 && 0) ||
                                (items?.rt_ratingResponse === 2 && 25) ||
                                (items?.rt_ratingResponse === 3 && 50) ||
                                (items?.rt_ratingResponse === 4 && 75) ||
                                (items?.rt_ratingResponse === 5 && 100)
                              }
                              onChange={(e) =>
                                handleSliderChange(
                                  e,
                                  data[pageNo],
                                  items?.aq_id
                                )
                              }
                              // aria-label="Custom marks"

                              step={25}
                              classes={classes.mark}
                              marks={marks}
                            />
                            <FormControl className={classes.radiocontrol}>
                              <RadioGroup
                                column
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                                onClick={(e) =>
                                  handleRadioChange(e, items?.aq_id)
                                }
                              >
                                <FormControlLabel
                                  value="initialVal"
                                  control={
                                    <Radio
                                      checked={items?.rt_ratingResponse === 9}
                                      size="small"
                                    />
                                  }
                                  labelPlacement="bottom"
                                  label={
                                    <FormLabel
                                      style={{
                                        fontSize: "0.8rem",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Not in a position to observe
                                    </FormLabel>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </Stack>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid sx={{ marginLeft: "1rem" }} item xs={12} md={12}>
                          <Stack
                            sx={{
                              fontSize: "1rem",
                              textAlign: "start",
                              fontWeight: 400,
                            }}
                          >
                            Optional Comments{" "}
                          </Stack>
                          <Stack
                            sx={{
                              width: "98%",
                              fontSize: "0.8rem",
                              textAlign: "end",
                            }}
                          >
                            {" "}
                            {255 -
                              (items?.rt_optionalComment === null
                                ? 0
                                : items?.rt_optionalComment.length)}{" "}
                            Characters remaining
                          </Stack>
                          <TextField
                            sx={{ width: "98%" }}
                            hiddenLabel
                            variant="filled"
                            value={
                              items?.rt_optionalComment
                                ? items?.rt_optionalComment
                                : ""
                            }
                            onChange={(e) =>
                              handleOptionalChange(e, items?.aq_id)
                            }
                          />
                          {items?.rt_optionalComment &&
                            items?.rt_optionalComment.length > 255 && (
                              <FormHelperText
                                error={true}
                                sx={{ color: "red" }}
                              >
                                Optional Comments should have less than or equal
                                to 255 characters
                              </FormHelperText>
                            )}
                        </Grid>
                      );
                    }
                  })}
            </>
          )}

        {!lastPageToggle && (
          <Grid xs={12} md={12}>
            {data.length > 0 &&
              data[pageNo]?.data?.length > 0 &&
              (data[pageNo]?.data[0]?.aq_questionType === "SR" ||
                data[pageNo]?.data[0]?.aq_questionType === "SLR") && (
                <Stack sx={{ paddingBottom: "1.4rem" }}>
                  <ShortResponse
                    value={slValue}
                    change={handleSlChange}
                    data={data[pageNo]?.data[0]}
                    title={data[pageNo]?.data[0].aq_questionType}
                  />
                </Stack>
              )}
          </Grid>
        )}

        {lastPageToggle && (
          <Grid xs={12} md={12}>
            <SurveyFinishPage />
          </Grid>
        )}

        {!lastPageToggle && (
          <Grid className={classes.buttonParent} item xs={12}>
            <Stack className={classes.buttonParentOne}>
              <Stack className={classes.buttonParentTwo}>
                {!lastPageToggle &&
                  data.length > 0 &&
                  data[pageNo]?.pageNumber !== 1 && (
                    <Button
                      className={classes.prebutton}
                      onClick={handlePageCountPreviousClick}
                      sx={{}}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Previous
                    </Button>
                  )}
                {data.length > 0 &&
                  data.length !== data[pageNo]?.pageNumber && (
                    <Button
                      className={classes.nextbut}
                      sx={{}}
                      disabled={
                        data.length > 0 &&
                        (data[pageNo]?.data[0]?.aq_questionType === "Rating" ||
                          data[pageNo]?.data[0]?.aq_questionType === "OC")
                          ? ratingData.length > 0
                            ? ratingData.some(
                                (dat) =>
                                  (dat?.aq_questionType === "Rating" &&
                                    dat.rt_ratingResponse === null) ||
                                  (dat?.aq_questionType === "OC" &&
                                    dat.rt_optionalComment?.length > 255)
                              ) === true
                              ? "disabled"
                              : null
                            : data[pageNo]?.data.some(
                                (dat) =>
                                  (dat?.aq_questionType === "Rating" &&
                                    dat.rt_ratingResponse === null) ||
                                  (dat?.aq_questionType === "OC" &&
                                    dat.rt_optionalComment?.length > 255)
                              ) === true
                            ? "disabled"
                            : null
                          : data.length > 0 &&
                            data[pageNo]?.data[0]?.aq_questionType === "SR"
                          ? !slValue || slValue?.length > 255
                            ? "disabled"
                            : null
                          : data[pageNo]?.data[0]?.aq_questionType === "SLR" &&
                            (!slValue || slValue?.length > 500)
                          ? "disabled"
                          : null
                      }
                      onClick={handlePageCountClick}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Next
                    </Button>
                  )}
                {data.length > 0 &&
                  data.length === data[pageNo]?.pageNumber && (
                    <Button
                      className={classes.reviewbut}
                      disabled={
                        data.length > 0 &&
                        (data[pageNo]?.data[0]?.aq_questionType === "Rating" ||
                          data[pageNo]?.data[0]?.aq_questionType === "OC")
                          ? ratingData.length > 0
                            ? ratingData.some(
                                (dat) =>
                                  (dat?.aq_questionType === "Rating" &&
                                    dat.rt_ratingResponse === null) ||
                                  (dat?.aq_questionType === "OC" &&
                                    dat.rt_optionalComment?.length > 255)
                              ) === true
                              ? "disabled"
                              : null
                            : data[pageNo]?.data.some(
                                (dat) =>
                                  (dat?.aq_questionType === "Rating" &&
                                    dat.rt_ratingResponse === null) ||
                                  (dat?.aq_questionType === "OC" &&
                                    dat.rt_optionalComment?.length > 255)
                              ) === true
                            ? "disabled"
                            : null
                          : data.length > 0 &&
                            data[pageNo]?.data[0]?.aq_questionType === "SR"
                          ? !slValue || slValue?.length > 255
                            ? "disabled"
                            : null
                          : data[pageNo]?.data[0]?.aq_questionType === "SLR" &&
                            (!slValue || slValue?.length > 500)
                          ? "disabled"
                          : null
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={navigatePreviewClick}
                    >
                      Review
                    </Button>
                  )}

                {/* {data.length > 0 && data.length === data[pageNo].pageNumber && (
                  <Button
                    disabled={
                      data.length > 0 &&
                      (data[pageNo]?.data[0]?.aq_questionType === "Rating" ||
                        data[pageNo]?.data[0]?.aq_questionType === "OC")
                        ? ratingData.length > 0
                          ? ratingData.some(
                              (dat) =>
                                dat?.aq_questionType === "Rating" &&
                                dat.rt_ratingResponse === null
                            ) === true
                            ? "disabled"
                            : null
                          : data[pageNo]?.data.some(
                              (dat) =>
                                dat?.aq_questionType === "Rating" &&
                                dat.rt_ratingResponse === null
                            ) === true
                          ? "disabled"
                          : null
                        : data.length > 0 &&
                          data[pageNo]?.data[0]?.aq_questionType === "SR"
                        ? !slValue
                          ? "disabled"
                          : null
                        : data[pageNo]?.data[0]?.aq_questionType === "SLR" &&
                          !slValue
                        ? "disabled"
                        : null
                    }
                    sx={{ marginLeft: "1rem", width: "8rem" }}
                    onClick={handlePageFinish}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Finish
                  </Button>
                )} */}
              </Stack>

              <Stack className={classes.progressParent}>
                {data.length > 0 && (
                  <Stack className={classes.progressParentTwo} sx={{}}>
                    <LinearProgressWithLabel
                      color="inherit"
                      sx={{ height: "1rem", borderRadius: "1rem" }}
                      value={progress}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default Assesment;
