import React, { useState, useEffect } from "react";
// components

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import { FormHelperText } from "@mui/material";

const Assesment = ({ title, change, data, value, isPreview }) => {
  let client = localStorage.getItem("clientName");
  let firstName = client?.split("\n")[0];

  let isCheckingSelf = localStorage.getItem("role");

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
            fontFamily: "dinBold",
            alignSelf: "flex-start",
          }}
          variant="h6"
          gutterBottom
          component="div"
        >
          Written Response
          {/* {title === "SR" ? "Short Response" :"Short or Long Response"} */}
        </Typography>
      </Grid>
      <Grid sx={{ marginLeft: "1rem" }} item xs={12} md={12}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 400,
          }}
          gutterBottom
          component="div"
        >
          Please respond to the following question(s):
        </Typography>
      </Grid>
      <Grid sx={{ marginLeft: "1rem" }} item xs={12} md={12}>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: 400,
          }}
          gutterBottom
          component="div"
        >
          
          {isCheckingSelf === "Self"
            ? title === "SR" && <span>{data?.aq_questionName}</span>
            : title === "SR" && (
                <span>
                  {firstName} {data?.aq_questionName}
                </span>
              )}

          {isCheckingSelf === "Self"
            ? title === "SLR" && (
                <span>
                  {data?.aq_questionName?.includes("[Leader]")
                    ? data?.aq_questionName?.replaceAll("[Leader]", ``)
                    : data?.aq_questionName}
                </span>
              )
            : title === "SLR" && (
                <span>
                  {data?.aq_questionName?.includes("[Leader]")
                    ? data?.aq_questionName?.replaceAll(
                        "[Leader]",
                        `${firstName}`
                      )
                    : data?.aq_questionName}
                </span>
              )}
        </Typography>
      </Grid>

      <Grid sx={{ marginLeft: "1rem" }} item xs={12} md={12}>
        {!isPreview && (
          <Stack sx={{ width: "98%", fontSize: "0.8rem", textAlign: "end" }}>
            {(title === "SR" ? 255 : 500) - value?.length <= 0
              ? 0
              : (title === "SR" ? 255 : 500) - value?.length
              ? (title === "SR" ? 255 : 500) - value?.length <= 0
                ? 0
                : (title === "SR" ? 255 : 500) - value?.length
              : title === "SR"
              ? 255
              : 500}{" "}
            Characters remaining
          </Stack>
        )}

        <TextField
          InputProps={{
            sx: { minHeight: title === "SR" ? 300 : 350 },
          }}
          multiline
          disabled={isPreview ? "disabled" : null}
          sx={{ width: "98%" }}
          variant="filled"
          value={value}
          onChange={(e) => change(e, data)}
          helperText={
            title === "SR"
              ? value?.length > 255 && (
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    Response should have less than or equal to 255 characters
                  </FormHelperText>
                )
              : value?.length > 500 && (
                  <FormHelperText error={true} sx={{ color: "red" }}>
                    Response should have less than or equal to 500 characters
                  </FormHelperText>
                )
          }
        />
      </Grid>
    </Grid>
  );
};

export default Assesment;
